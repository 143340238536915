import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import ORImage from 'next/image';
import { graphql, useFragment } from 'react-relay';
import { lang, useTranslation } from '@/helpers/translate';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import miniql from '@/helpers/miniQueryLoaderv3';

const fragment = graphql`
  fragment amPopin_userConnected on Query {
    userConnected: viewer {
      crmData {
        corpo {
          hubspot_owner_id
          hubspotOwnerData
        }
      }
    }
  }
`;
const query = graphql`
  query amPopinQuery {
    ...amPopin_userConnected
  }
`;

const MiniQLV3OwnerData = miniql({
  Component: ({ data, setData }) => {
    const dataUser = useFragment(fragment, data);
    useEffect(() => {
      setData(dataUser?.userConnected?.crmData?.corpo);
    }, [dataUser]);
    return null;
  },
  query,
});

function Am() {
  const { t } = useTranslation();
  const [showAm, setShowAm] = useState(true);
  const [showInfos, setShowInfos] = useState(false);
  const langCurrent = lang();

  const [isRender, setRender] = useState(false);
  const [loadingOwnerData, setLoadingOwnerData] = useState(true);
  const [ownerData, setOwnerData] = useState();
  const userConnected = useZusUserConnectedStore((state) => state.userData);
  const [amMobile] = useState((process?.env?.NEXT_PUBLIC_AM_MOBILE || '').split('|').reduce((acc, am) => {
    const [hubspotId, mobile] = am.split(',');
    acc[hubspotId] = mobile;
    return acc;
  }, {}));
  useEffect(() => {
    setRender(true);
  }, []);

  const handleToggleClick = () => {
    setShowInfos((prevShowInfos) => !prevShowInfos);
  };

  const getHelpUrl = () => {
    const url = [''];
    if (langCurrent !== 'en') url.push(langCurrent);
    url.push('corpo/help');
    return url.join('/');
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    window.location.href = getHelpUrl();
  };

  const getFullName = () => `${ownerData?.hubspotOwnerData?.firstName || ''} ${ownerData?.hubspotOwnerData?.lastName || ''}`;

  const getImageSrc = () => `/img/${ownerData?.hubspot_owner_id}.jpg`;

  if (!isRender) return null;
  if (!userConnected?.corpo?.ref) return null;
  if (loadingOwnerData) {
    return <MiniQLV3OwnerData setData={(data) => {
      setLoadingOwnerData(false);
      setOwnerData(data);
    }}
    />;
  }
  if (!ownerData?.hubspotOwnerData?.email) return null;

console.log('ownerData?.hubspot_owner_id', ownerData?.hubspot_owner_id);

  return (
    <Transition
      show={showAm}
      enter="transition-all duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {(ref) => (
        <div
          ref={ref}
          onClick={handleToggleClick}
          className="fixed z-10 flex items-center justify-center cursor-pointer w-fit right-6 left-6 lg:bottom-16 lg:right-36 lg:left-auto bottom-20"
        >
          {showInfos ? (
            <div className="flex flex-col w-full px-4 py-3 bg-black rounded-lg lg:rounded-xl">
              <div className="flex flex-col px-2 py-3 lg:flex-row text-wrap">
                <div className="relative hidden w-24 h-24 overflow-hidden bg-white rounded-lg lg:block shrink">
                  <ORImage
                    alt={t('workstars:am_img_alt')}
                    className="object-contain z-5 avatar"
                    fill
                    src={getImageSrc()}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </div>
                <div className="flex-none pr-3 lg:pl-5 ">
                  <p className="font-medium leading-tight text-white text-wrap">{getFullName()}</p>
                  <p className="text-sm leading-tight text-dark-light text-wrap">
                    {t('workstars:title_am_corpo')} <span className="text-white-dark4">{userConnected?.corpo?.name}</span>
                  </p>
                  <div className="flex flex-col pt-3 gap-y-1">
                    <p className="text-xs text-white text-wrap lg:text-sm">
                      <a href={`tel:${amMobile[ownerData?.hubspot_owner_id]}`}>{amMobile[ownerData?.hubspot_owner_id]}</a> <span className="text-dark">{t('workstars:am_opening')}</span>
                    </p>
                    <p className="text-xs text-white lg:text-sm">
                      <a href={`mailto:${ownerData?.hubspotOwnerData?.email}`}>{ownerData?.hubspotOwnerData?.email}</a>
                    </p>
                  </div>
                  <button
                    onClick={handleButtonClick}
                    className="flex items-center justify-center px-3 py-1 mt-4 text-sm text-white border hover:bg-black bg-black-light border-dark-deep"
                  >
                    {t('corpo:layout.help')}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center transition ease-in-out bg-black shadow-lg rounded-xl hover:bg-black-light hover:scale-105">
                <div className="relative m-1 overflow-hidden bg-white rounded-lg w-11 h-11 shrink">
                  <ORImage
                    alt={t('workstars:am_img_alt')}
                    className="object-contain z-5 avatar"
                    fill
                    src={getImageSrc()}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </div>
                <div className="flex-none w-40">
                  <p className="pl-2 pr-3 font-medium leading-tight text-white text-wrap">{getFullName()}</p>
                  <p className="pl-2 pr-3 text-xs leading-tight text-dark-light text-wrap">{t('workstars:title_am_short')}</p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Transition>
  );
}

export default Am;
